import { de } from 'date-fns/locale';
import * as R from 'ramda';
import app from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import {
   updateGroupObject,
   removeGroupObject,
} from '../../modules/GroupListing/utils/convertMembers';
import { createModifiedIndex } from '../../modules/GroupListing/utils/groupAdminIndexHelpers';

const config = {
   apiKey: 'AIzaSyDJ7mdzyKYGS3PcRXlaQNwy42AORSYI18Q',
   authDomain: 'app.intheoffice.io',
   // authDomain: 'msteams.intheoffice.io',
   // authDomain: 'staging-intheoffice.web.app',
   databaseURL: 'https://intheoffice-979c4.firebaseio.com', //"http://localhost:8080/?ns=intheoffice-979c4",
   // databaseURL: 'https://intheoffice-979c4.firebaseio.com', //"http://localhost:8080/?ns=intheoffice-979c4",
   projectId: 'intheoffice-979c4',
   storageBucket: 'intheoffice-979c4.appspot.com',
   messagingSenderId: '271831150171',
   appId: '1:271831150171:web:c3befd5f1d5883065b997e',
   measurementId: 'G-WP84NK7G7D',
};

class Firebase {
   constructor() {
      app.initializeApp(config);

      this.auth = app.auth();
      this.db = app.firestore();
      // this.logs = app.firestore('logs');
      this.functions = app.functions();
      this.storage = app.storage();
      this.microsoftProvider = new app.auth.OAuthProvider('microsoft.com').addScope(
         'calendars.read',
      );
   }

   // *** AUth API ***
   doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);

   doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

   doSignInWithMicrosoft = () => this.auth.signInWithRedirect(this.microsoftProvider);
   doSignInWithMicrosoftMSTeams = () => this.auth.signInWithPopup(this.microsoftProvider);

   getMicrosoftCreds = () =>
      this.auth
         .getRedirectResult()
         .then(function (result) {
            return result;
         })
         .catch(function (error) {
            console.log(error, 'from firebase');
            return error;
         });

   // getMicrosoftCreds = () => this.auth.getRedirectResult()
   // .then((result) => {
   //   console.log(result, 'reditect result');
   // })
   // .catch((error) => {
   //   console.log(error, 'error');
   //   // Handle error.
   // });
   accountExistsDifferentCreds = (email) => this.auth.fetchSignInMethodsForEmail(email);

   doSignOut = () =>
      this.auth.signOut().then(() => {
         window.location.reload(false);
      });

   doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

   doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

   doSendEmailVerification = () =>
      this.auth.currentUser.sendEmailVerification({
         url: 'https://app.intheoffice.io',
      });

   // *** custom user management *** //
   doCreateUserWithEmail = (email) => this.auth.createUser(email);

   currentFirebaseUser = () => this.auth.currentUser;

   // *** Merge Auth and DB User API *** //
   onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged((authUser) => {
         if (authUser) {
            this.user(authUser.uid)
               .get()
               .then((snapshot) => {
                  const dbUser = snapshot.data();
                  //might not need the if statement if saving to the DB starts to work.
                  if (dbUser) {
                     // default empty roles
                     if (!dbUser.roles) {
                        dbUser.roles = {};
                     }

                     // merge auth and db user
                     authUser = {
                        uid: authUser.uid,
                        email: authUser.email,
                        emailVerified: authUser.emailVerified,
                        providerData: authUser.providerData,
                        ...dbUser,
                     };

                     next(authUser);
                  }
               });
         } else {
            fallback();
         }
      });

   currentUserId = (uid) =>
      this.auth.onAuthStateChanged((authUser) => {
         if (authUser) {
            return authUser.uid;
         }
      });

   logToDb = (path, obj, options = { merge: true }) => {
      // path: string
      // obj = object
      // options = object

      const data = {
         ...obj,
         date: firebase.firestore.FieldValue.serverTimestamp(),
      };

      this.db
         .doc(path)
         .set({ [new Date().toISOString().split('T')[1]]: { ...data } }, { ...options });
   };

   allCos = () => this.db.collection('company').orderBy('profile.creationDate', 'desc');
   allLocations = () => this.db.collection('location');
   getUsersInArray = (cid, data) =>
      this.db
         .collection(`company/${cid}/people`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', data);

   // *** Domains *** //
   checkDomain = (domain) =>
      this.db.collection(`domains`).where('usedDomains', 'array-contains-any', [domain]);
   saveDomain = (cid) => this.db.doc(`domains/${cid}`);
   getDomainByCo = (cid) => this.db.collection('domains').where('cid', '==', cid);
   checkValidEmail = (domain) =>
      this.db.collection(`domains`).where('usedDomains', 'array-contains-any', [domain]);
   changeDomains = (domains, cid, domId) => {
      const batch = this.db.batch();
      let used = [];
      let allowed = [];
      Object.entries(domains).map(([key, value]) => {
         used.push(key);
         if (value) {
            allowed.push(key);
         }
      });
      let co = this.db.doc(`company/${cid}`);
      batch.set(co, { domains: { used, allowed } }, { merge: true });
      let dom = this.db.doc(`domains/${domId}`);
      batch.set(dom, { usedDomains: used, allowedDomains: allowed }, { merge: true });
      batch.commit();
   };
   // *** User API ***

   user = (uid) => this.db.collection('users').doc(`${uid}`);
   users = () => this.db.collection('users');
   userByEmail = (email) => this.db.collection('users').where('email', '==', email);

   // *** Company API ***

   // companySetUpPeople = cid => this.db.ref(`company/`)

   newPerson = async (
      lid,
      cid,
      data,
      uid,
      visitorsEnabled,
      // , grps
   ) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const co = this.db.collection(`company/${cid}/people`).doc(uid);
      batch.set(co, data);
      const setup = this.db.doc(`location/${lid}`);
      const locData = { setup: { people: true }, people: increment };
      batch.set(setup, locData, { merge: true });
      const coRef = this.db.doc(`company/${cid}`);
      batch.set(coRef, { totalPeople: increment }, { merge: true });
      if (visitorsEnabled) {
         const visitorIndex = this.db.doc(`location/${lid}/visitors/internalDetails`);
         batch.set(
            visitorIndex,
            { [data.email]: `${data.name.firstName} ${data.name.lastName}` },
            { merge: true },
         );
      }
      const coIndex = this.db.doc(`company/${cid}/indexes/people`);
      const locIndex = this.db.doc(`location/${lid}/indexes/people`);
      batch.set(
         coIndex,
         { [uid]: { email: data.email, name: data.name } },
         { merge: true },
      );
      batch.set(locIndex, { [uid]: { name: data.name } }, { merge: true });
      // let gIndex = {};
      // grps.new.map((gid) => {
      //    gIndex[gid] = { count: increment };
      //    const groupsRef = this.db.doc(`location/${lid}/groups/${gid}`);
      //    batch.set(
      //       groupsRef,
      //       {
      //          count: increment,
      //          members: {
      //             [uid]: {
      //                name: data.name,
      //             },
      //          },
      //       },
      //       { merge: true },
      //    );
      // });
      // const groupIndex = this.db.doc(`location/${lid}/indexes/groups`);
      // batch.set(groupIndex, gIndex, { merge: true });
      await batch.commit();
   };
   deletePerson = async (lid, cid, uid, email, visitorsEnabled, grps) => {
      const batch = this.db.batch();
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const co = this.db.doc(`company/${cid}/people/${uid}`);
      batch.delete(co);
      const loc = this.db.doc(`location/${lid}`);
      batch.set(loc, { people: decrement }, { merge: true });
      const coRef = this.db.doc(`company/${cid}`);
      batch.set(coRef, { totalPeople: decrement }, { merge: true });
      if (visitorsEnabled) {
         const visitorIndex = this.db.doc(`location/${lid}/visitors/internalDetails`);
         batch.set(
            visitorIndex,
            { [email]: firebase.firestore.FieldValue.delete() },
            { merge: true },
         );
      }
      const coIndex = this.db.doc(`company/${cid}/indexes/people`);
      batch.set(
         coIndex,
         { [uid]: firebase.firestore.FieldValue.delete() },
         { merge: true },
      );
      const locIndex = this.db.doc(`location/${lid}/indexes/people`);
      batch.set(
         locIndex,
         { [uid]: firebase.firestore.FieldValue.delete() },
         { merge: true },
      );
      // let gIndex = {};
      // grps.removed.map((gid) => {
      //    gIndex[gid] = { count: decrement };
      //    const groupsRef = this.db.doc(`location/${lid}/groups/${gid}`);
      //    batch.set(
      //       groupsRef,
      //       { count: decrement, members: { [uid]: deleteField } },
      //       { merge: true },
      //    );
      // });
      // const groupIndex = this.db.doc(`location/${lid}/indexes/groups`);
      // batch.set(groupIndex, gIndex, { merge: true });
      await batch.commit();
   };
   deleteInvitedPerson = async (
      lid,
      cid,
      uid,
      email,
      visitorsEnabled,
      routineEnabled,
      // grps,
   ) => {
      const batch = this.db.batch();
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const co = this.db.doc(`company/${cid}/people/${uid}`);
      batch.delete(co);
      const loc = this.db.doc(`location/${lid}`);
      batch.set(loc, { people: decrement }, { merge: true });
      const coRef = this.db.doc(`company/${cid}`);
      batch.set(coRef, { totalPeople: decrement }, { merge: true });
      if (visitorsEnabled) {
         const visitorIndex = this.db.doc(`location/${lid}/visitors/internalDetails`);
         batch.set(
            visitorIndex,
            { [email]: firebase.firestore.FieldValue.delete() },
            { merge: true },
         );
      }
      if (routineEnabled) {
         const coRoutIndex = this.db.doc(`company/${cid}/indexes/routines`);
         batch.set(
            coRoutIndex,
            { [uid]: firebase.firestore.FieldValue.delete() },
            { merge: true },
         );
      }
      const coIndex = this.db.doc(`company/${cid}/indexes/people`);
      batch.set(
         coIndex,
         { [uid]: firebase.firestore.FieldValue.delete() },
         { merge: true },
      );
      const locIndex = this.db.doc(`location/${lid}/indexes/people`);
      batch.set(
         locIndex,
         { [uid]: firebase.firestore.FieldValue.delete() },
         { merge: true },
      );
      const user = this.db.doc(`users/${uid}`);
      batch.delete(user);
      // let gIndex = {};
      // grps.removed.map((gid) => {
      //    gIndex[gid] = { count: decrement };
      //    const groupsRef = this.db.doc(`location/${lid}/groups/${gid}`);
      //    batch.set(
      //       groupsRef,
      //       { count: decrement, members: { [uid]: deleteField } },
      //       { merge: true },
      //    );
      // });
      // const groupIndex = this.db.doc(`location/${lid}/indexes/groups`);
      // batch.set(groupIndex, gIndex, { merge: true });
      await batch.commit();
   };
   getPerson = (cid, uid) => this.db.doc(`company/${cid}/people/${uid}`);
   getPersonByEmail = (cid, email) =>
      this.db.collection(`company/${cid}/people/`).where('email', '==', email);
   emailExistsLocation = (lid, emails) =>
      this.db.collection(`location/${lid}/people/`).where('email', 'in', emails);
   emailExists = (cid, email) =>
      this.db.collection(`company/${cid}/people/`).where('email', '==', email);
   emailDuplicate = (lids, email) =>
      this.db.doc(`location/W9q35ohO38TKEy1f0K3D/spaceBookings/20200907`);

   bulkImportPeople = (data, cid, lid, features) => {
      const batch = this.db.batch();
      const loc = this.db.doc(`location/${lid}`);
      const coRef = this.db.doc(`company/${cid}`);
      const coIndex = this.db.doc(`company/${cid}/indexes/people`);
      const locIndex = this.db.doc(`location/${lid}/indexes/people`);
      const increment = firebase.firestore.FieldValue.increment(1);
      Object.values(data).map((value) => {
         if (value.exists === false && value.valid === true) {
            const person = this.db.collection(`company/${cid}/people`).doc();
            let details = {
               name: {
                  firstName: value.firstname,
                  lastName: value.lastname,
               },
               cid: cid,
               favouriteSpaces: [],
               email: value.email.toLowerCase(),
               lid: [lid],
               gid: [],
               status: {
                  statusId: 'notInvited',
               },
            };
            if (features.visitors || features.spaceBookings) {
               const visitorIndex = this.db.doc(
                  `location/${lid}/visitors/internalDetails`,
               );
               batch.set(
                  visitorIndex,
                  {
                     [details.email]: `${details.name.firstName} ${details.name.lastName}`,
                  },
                  { merge: true },
               );
            }
            batch.set(person, details);
            batch.set(loc, { people: increment }, { merge: true });
            batch.set(coRef, { totalPeople: increment }, { merge: true });
            batch.set(
               coIndex,
               { [person.id]: { email: details.email, name: details.name } },
               { merge: true },
            );
            batch.set(locIndex, { [person.id]: { name: details.name } }, { merge: true });
         }
      });
      batch.commit();
   };

   loadDemoWeek = (weekDates, user) => {
      function shuffleArray(array) {
         for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
         }
         return array[0];
      }
      let statuses = [
         'ito',
         'wfh',
         'ito',
         'wfh',
         'ito',
         'away',
         'offsite',
         'offsite',
         'ito',
         'wfh',
         'ito',
         'wfh',
         'ito',
         'offsite',
      ];
      Object.entries(weekDates).map(([key, value]) => {
         if (key != 0 && key != 6) {
            var schedDocRef = this.db.doc(
               `company/${user.cid}/people/${user.uid}/schedule/${value.ref}`,
            );
            var locSchedDocRef = this.db.doc(
               `location/${user.lid}/schedule/${value.ref}`,
            );
            var locSlDocRef = this.db.doc(
               `location/${user.lid}/statusLevels/${value.ref}`,
            );
            return this.db
               .runTransaction((transaction) => {
                  return transaction.get(schedDocRef).then((sfDoc) => {
                     if (!sfDoc.exists) {
                        let status = shuffleArray(statuses);
                        transaction.set(
                           schedDocRef,
                           { lid: user.lid[0], status },
                           { merge: true },
                        );
                        transaction.set(
                           locSchedDocRef,
                           { [user.uid]: { status } },
                           { merge: true },
                        );
                        transaction.set(
                           locSlDocRef,
                           { [status]: firebase.firestore.FieldValue.increment(1) },
                           { merge: true },
                        );
                     } else {
                        // console.log(sfDoc.data())
                     }
                  });
               })
               .then(() => {
                  console.log('Transaction successfully committed!');
               })
               .catch((error) => {
                  console.log('Transaction failed: ', error);
               });
         }
      });
   };

   bulkDeletePeople = (people, cid, lid, visitorsEnabled) => {
      const batch = this.db.batch();
      var num = people.length;
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const multiDecrement = firebase.firestore.FieldValue.increment(-num);
      const deleteField = firebase.firestore.FieldValue.delete();
      const coIndex = this.db.doc(`company/${cid}/indexes/people`);
      const locIndex = this.db.doc(`location/${lid}/indexes/people`);
      const visitorIndex = this.db.doc(`location/${lid}/visitors/internalDetails`);
      people.map((person) => {
         const user = this.db.doc(`company/${cid}/people/${person.uid}`);
         batch.delete(user);
         if (visitorsEnabled) {
            batch.set(
               visitorIndex,
               { [person.email]: firebase.firestore.FieldValue.delete() },
               { merge: true },
            );
         }
         batch.set(
            coIndex,
            { [person.uid]: firebase.firestore.FieldValue.delete() },
            { merge: true },
         );
         batch.set(
            locIndex,
            { [person.uid]: firebase.firestore.FieldValue.delete() },
            { merge: true },
         );
         let gIndex = {};
         if (person.groups) {
            Object.entries(person.groups).map(([key, value]) => {
               gIndex[key] = { count: decrement };
               const groupsRef = this.db.doc(`location/${lid}/groups/${key}`);
               batch.set(
                  groupsRef,
                  { count: decrement, members: { [person.uid]: deleteField } },
                  { merge: true },
               );
            });
            const groupIndex = this.db.doc(`location/${lid}/indexes/groups`);
            batch.set(groupIndex, gIndex, { merge: true });
         }
      });
      const noOfPeople = this.db.doc(`location/${lid}`);
      batch.set(noOfPeople, { people: multiDecrement }, { merge: true });
      const coRef = this.doc(`company/${cid}`);
      batch.set(coRef, { totalPeople: multiDecrement }, { merge: true });
      batch.commit();
   };
   resetPeopleLocationSchedule = (people, cid) => {
      const FieldValue = firebase.firestore.FieldValue;
      const decrement = firebase.firestore.FieldValue.increment(-1);
      Object.entries(people).map(([uid, value]) => {
         var sections = {};
         var i = 1;
         var sec = 0;
         var count = Object.keys(value.dates).length;
         if (count > 100) {
            var group = {};
            Object.entries(value.dates).map(([date, details]) => {
               group[date] = details;
               if (i == count || i % 100 === 0) {
                  sections[sec] = group;
                  group = {};
                  sec = sec + 1;
               }
               i = i + 1;
            });
         } else {
            sections[0] = value.dates;
         }
         Object.values(sections).map((days) => {
            const batch = this.db.batch();
            Object.entries(days).map(([date, details]) => {
               let offsiteLocs = {};
               if (details.bookingNo > 0) {
                  Object.entries(details.bookings).map(([bid, bookingsRef]) => {
                     if (bookingsRef.lid !== details.lid) {
                        offsiteLocs[bookingsRef.lid] = true;
                     }
                     const spaceBookingRef = this.db.doc(
                        `location/${bookingsRef.lid}/spaceBookings/${date}`,
                     );
                     batch.set(
                        spaceBookingRef,
                        {
                           [bookingsRef.sid]: {
                              bookingNo: decrement,
                              bookings: { [bid]: FieldValue.delete() },
                           },
                        },
                        { merge: true },
                     );
                     const spaceSchedRef = this.db.doc(
                        `location/${bookingsRef.lid}/spaces/${bookingsRef.sid}/schedule/${date}`,
                     );
                     batch.set(
                        spaceSchedRef,
                        {
                           bookingNo: decrement,
                           bookings: { [bid]: FieldValue.delete() },
                        },
                        { merge: true },
                     );
                  });
               }
               if (Object.keys(offsiteLocs).length > 0) {
                  Object.keys(offsiteLocs).map((offsiteLoc) => {
                     const locRef = this.db.doc(
                        `location/${offsiteLoc}/schedule/${date}`,
                     );
                     batch.set(locRef, { [uid]: FieldValue.delete() }, { merge: true });
                  });
               }
               if (details.visitorNo > 0) {
                  Object.entries(details.visitors).map(([visEmail, visDetails]) => {
                     const visitorsReg = this.db.doc(
                        `location/${details.lid}/visitors/${date}`,
                     );
                     batch.set(
                        visitorsReg,
                        { [visEmail]: FieldValue.delete(), visitorNo: decrement },
                        { merge: true },
                     );
                  });
               }
               const coRef = this.db.doc(`company/${cid}/people/${uid}/schedule/${date}`);
               batch.delete(coRef);
               if (details.status) {
                  const locRef = this.db.doc(`location/${details.lid}/schedule/${date}`);
                  batch.set(locRef, { [uid]: FieldValue.delete() }, { merge: true });
                  const statusRef = this.db.doc(
                     `location/${details.lid}/statusLevels/${date}`,
                  );
                  let statusLevel = {};
                  if (details.routine && details.routine !== '-' && details.overridden) {
                     statusLevel = {
                        ...statusLevel,
                        overridden: {
                           [details.routine]: decrement,
                        },
                        [details.status]: decrement,
                     };
                  }
                  if (
                     details.status === 'offsite' &&
                     details.offsiteLocation?.locationId
                  ) {
                     const offsiteSL = this.db.doc(
                        `location/${details.offsiteLocation.locationId}/statusLevels/${date}`,
                     );
                     batch.set(offsiteSL, { offsiteIto: decrement }, { merge: true });
                  }
                  if (details.status === 'split') {
                     statusLevel = {
                        ...statusLevel,
                        AM: {
                           [details.splitDay.AM]: decrement,
                        },
                        PM: {
                           [details.splitDay.PM]: decrement,
                        },
                     };
                     if (
                        details.splitDay.AM === 'offsite' &&
                        details.offsiteLocation?.AM.locationId
                     ) {
                        const offsiteSL = this.db.doc(
                           `location/${details.offsiteLocation.AM.locationId}/statusLevels/${date}`,
                        );
                        batch.set(
                           offsiteSL,
                           { AM: { offsiteIto: decrement } },
                           { merge: true },
                        );
                     }
                     if (
                        details.splitDay.PM === 'offsite' &&
                        details.offsiteLocation?.PM.locationId
                     ) {
                        const offsiteSL = this.db.doc(
                           `location/${details.offsiteLocation.PM.locationId}/statusLevels/${date}`,
                        );
                        batch.set(
                           offsiteSL,
                           { PM: { offsiteIto: decrement } },
                           { merge: true },
                        );
                     }
                  }
                  if (details.status !== 'split' && !details.routine) {
                     statusLevel = { [details.status]: decrement };
                  }
                  batch.set(statusRef, statusLevel, { merge: true });
               }
            });
            batch.commit();
         });
      });
   };

   company = (cid) => this.db.doc(`company/${cid}`);

   companies = () => this.db.collection('company');

   companyDetails = (cid) => this.db.doc(`company/${cid}`);
   companyPeopleIndex = (cid) => this.db.doc(`company/${cid}/indexes/people`);
   companyPeople = (cid) => this.db.collection(`company/${cid}/people`);
   companyPeopleArray = (cid, uids) =>
      this.db
         .collection(`company/${cid}/people`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', uids);
   companyPerson = (cid, uid) => this.db.doc(`company/${cid}/people/${uid}`);
   companyGroups = (cid) => this.db.doc(`company/${cid}/groups`);

   companySubscription = (cid) => this.db.doc(`company/${cid}/subscription/`);
   companyFeatures = (data) => {
      const batch = this.db.batch();
      const coRef = this.db.doc(`company/${data.cid}`);
      if (data.subscription === 'essential' && data.status) {
         let planChangeDate = new Date();
         var nextBillingDate = new Date();
         nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);
         batch.set(
            coRef,
            { subscription: { plan: 'premium', planChangeDate }, nextBillingDate },
            { merge: true },
         );
      }
      batch.set(coRef, { features: { [data.feature]: data.status } }, { merge: true });
      Object.keys(data.lids).map((lid) => {
         const locRef = this.db.doc(`location/${lid}`);
         data.subFeatures.map((sub) => {
            if (sub !== 'requests') {
               batch.set(locRef, { [sub]: data.status }, { merge: true });
            } else {
               batch.set(locRef, { actions: { [sub]: data.status } }, { merge: true });
            }
            if (
               (data.feature === 'visitors' && data.visitorInternalDetails) ||
               (data.feature === 'spaceBookings' && data.visitorInternalDetails)
            ) {
               const locVis = this.db.doc(`location/${lid}/visitors/internalDetails`);
               batch.set(locVis, data.visitorInternalDetails[lid]);
            }
            if (
               (data.feature === 'visitors' &&
                  !data.status &&
                  !data.companyEnabledFeatures.spaceBookings) ||
               (data.feature === 'spaceBookings' &&
                  !data.status &&
                  !data.companyEnabledFeatures.visitors)
            ) {
               const locVis = this.db.doc(`location/${lid}/visitors/internalDetails`);
               batch.delete(locVis);
            }
         });
      });

      batch.commit();
   };
   companyCurrentBill = (cid) => this.db.doc(`company/${cid}/billing/current`);
   companyPastBills = (cid) => this.db.collection(`company/${cid}/billing`);

   featureSwitch = (company, feature, visitorInternalDetails) => {
      const batch = this.db.batch();
      const coRef = this.db.doc(`company/${company.cid}`);
      if (company.subscription.plan !== 'premium') {
         let planChangeDate = new Date();
         var nextBillingDate = new Date();
         nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);
         batch.set(
            coRef,
            { subscription: { plan: 'premium', planChangeDate }, nextBillingDate },
            { merge: true },
         );
      }
      Object.keys(company.locations).map((loc) => {
         const locRef = this.db.doc(`location/${loc}`);
         switch (feature) {
            case 'reporting':
               batch.set(locRef, { reporting: true }, { merge: true });
               batch.set(coRef, { features: { reporting: true } }, { merge: true });
               break;
            case 'requests':
               batch.set(locRef, { actions: { requests: true } }, { merge: true });
               batch.set(locRef, { routine: true }, { merge: true });
               batch.set(locRef, { offsiteEnabled: true }, { merge: true });
               batch.set(
                  coRef,
                  { features: { advancedScheduling: true } },
                  { merge: true },
               );
               break;
            case 'groups':
               batch.set(locRef, { groups: true }, { merge: true });
               batch.set(coRef, { features: { groups: true } }, { merge: true });
               break;
            case 'routine':
               batch.set(locRef, { actions: { requests: true } }, { merge: true });
               batch.set(locRef, { routine: true }, { merge: true });
               batch.set(locRef, { offsiteEnabled: true }, { merge: true });
               batch.set(
                  coRef,
                  { features: { advancedScheduling: true } },
                  { merge: true },
               );
               break;
            case 'visitors':
               batch.set(locRef, { visitorsEnabled: true }, { merge: true });
               batch.set(coRef, { features: { visitors: true } }, { merge: true });
               const locVis = this.db.doc(`location/${loc}/visitors/internalDetails`);
               batch.set(locVis, visitorInternalDetails[loc], { merge: true });
               break;
         }
      });
      batch.commit();
   };

   // **** Locations *** //
   locations = () => this.db.collection('location');
   location = (lid) => this.db.doc(`location/${lid}`);
   locationSchedule = (lid, date) => this.db.doc(`location/${lid}/schedule/${date}`);
   locationGroups = (lid) => this.db.collection(`location/${lid}/groups`);
   locationGroupsIndex = (lid) => this.db.doc(`location/${lid}/indexes/groups`);
   locationGroupDetails = (lid, gid) => this.db.doc(`location/${lid}/groups/${gid}`);
   locationSetUp = (lid, setUpField) => this.db.doc(`location/${lid}`).update(setUpField);
   locationMultiGroups = (lid, gids) =>
      this.db
         .collection(`location/${lid}/groups`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', gids);
   // locationPeople = (lid) => this.db.doc(`location/${lid}`).collection('people');
   locationPeopleIndex = (lid) => this.db.doc(`location/${lid}/indexes/people`);
   locationPeople = (lid, cid) =>
      this.db.collection(`company/${cid}/people`).where('lid', 'array-contains', lid);
   locationPerson = (lid, uid) =>
      this.db.doc(`location/${lid}`).collection('people').doc(`${uid}`);
   locationCapacitySet = (lid, data) =>
      this.db.doc(`location/${lid}`).set(data, { merge: true });
   locationToggleSpaces = (lid, status) =>
      this.db.doc(`location/${lid}`).set({ spacesEnabled: status }, { merge: true });
   locationVisitorsToggle = (lid, status) =>
      this.db.doc(`location/${lid}`).set({ visitorsEnabled: status }, { merge: true });
   locationVisitorAlertToggleOn = (lid, status, people) => {
      const batch = this.db.batch();
      let locVis = this.db.doc(`location/${lid}/visitors/internalDetails`);
      batch.set(locVis, people, { merge: true });
      let loc = this.db.doc(`location/${lid}`);
      batch.set(loc, { visitorsAlertEnabled: true }, { merge: true });
      batch.commit();
   };
   locationPeopleNumberChange = (lid, amount) =>
      this.db
         .doc(`location/${lid}`)
         .set(
            { people: firebase.firestore.FieldValue.increment(amount) },
            { merge: true },
         );
   locationVisitorIndexChange = (newLid, oldLid, user) => {
      const batch = this.db.batch();
      const old = this.db.doc(`location/${oldLid}/visitors/internalDetails`);
      batch.set(
         old,
         { [user.email]: firebase.firestore.FieldValue.delete() },
         { merge: true },
      );
      const newLoc = this.db.doc(`location/${newLid}/visitors/internalDetails`);
      batch.set(
         newLoc,
         { [user.email]: `${user.name.firstName} ${user.name.lastName}` },
         { merge: true },
      );
      batch.commit();
   };
   newLocation = async (lid, user, data, company, locAdmins) => {
      data.profile = { creationDate: firebase.firestore.FieldValue.serverTimestamp() };
      data.locationAdmins = locAdmins;
      data.people = 0;
      data.cid = company.cid;
      if (!data.virtual) {
         data.rules = { totalMaxCapacity: 100 };
         data.capacity = { unit: 'people' };
         data.setup = {
            capacity: false,
            people: false,
            status: false,
            done: false,
         };
         data.spacesEnabled = false;
         data.offsiteEnabled = false;
         data.visitorsEnabled = false;
         data.reporting = false;
         data.groups = false;
         data.multisite = false;
         data.routine = false;
         data.actions = {
            meetings: false,
            requests: true,
         };
         if (company.features.advancedScheduling) {
            data.offsiteEnabled = true;
            data.routine = true;
            data.actions.requests = true;
         }
         if (company.features.spaceBookings) {
            data.spacesEnabled = true;
         }
         if (company.features.reporting) {
            data.reporting = true;
         }
         if (company.features.groups) {
            data.groups = true;
         }
         if (company.features.multisite) {
            data.multisite = true;
         }
         if (company.features.visitors) {
            data.visitorsEnabled = true;
         }
      }
      const batch = this.db.batch();

      let us = { locationAdmin: user.locationAdmin };
      locAdmins.map((admin) => {
         const userSave = this.db.doc(`users/${admin}`);
         batch.set(userSave, us, { merge: true });
         const coPeople = this.db.doc(`company/${user.cid}/people/${admin}`);
         batch.set(coPeople, us, { merge: true });
      });
      const coUpdate = this.db.doc(`company/${user.cid}`);
      let co = { locations: { [lid]: data.name } };
      batch.set(coUpdate, co, { merge: true });
      const locUpdate = this.db.doc(`location/${lid}`);
      batch.set(locUpdate, data, { merge: true });
      await batch.commit();
   };
   archiveLocation = async (company, lid, location, archive) => {
      const FieldValue = firebase.firestore.FieldValue;
      const batch = this.db.batch();
      const comp = this.db.doc(`company/${company.cid}`);
      const loc = this.db.doc(`location/${lid}`);
      if (archive) {
         batch.set(loc, { archived: true }, { merge: true });
         batch.set(comp, { locations: { [lid]: FieldValue.delete() } }, { merge: true });
         batch.set(
            comp,
            { locationsArchived: { [lid]: location.name } },
            { merge: true },
         );
      } else {
         batch.set(loc, { archived: false }, { merge: true });
         batch.set(comp, { locations: { [lid]: location.name } }, { merge: true });
         batch.set(
            comp,
            { locationsArchived: { [lid]: FieldValue.delete() } },
            { merge: true },
         );
      }
      batch.commit();
   };
   locationDelete = async (lid, location, company) => {
      const batch = this.db.batch();
      const comp = this.db.doc(`company/${company.cid}`);
      batch.set(
         comp,
         { locations: { [lid]: firebase.firestore.FieldValue.delete() } },
         { merge: true },
      );
      const loc = this.db.doc(`location/${lid}`);
      batch.delete(loc);
      location.locationAdmins.map((admin) => {
         const user = this.db.doc(`users/${admin}`);
         batch.set(
            user,
            { locationAdmin: firebase.firestore.FieldValue.arrayRemove(lid) },
            { merge: true },
         );
      });
      await batch.commit();
   };
   updateLocationName = (cid, lid, locName) => {
      const batch = this.db.batch();
      const locUpdate = this.db.doc(`location/${lid}`);
      let name = { name: locName };
      batch.set(locUpdate, name, { merge: true });
      const coUpdate = this.db.doc(`company/${cid}`);
      let co = { locations: { [lid]: locName } };
      batch.set(coUpdate, co, { merge: true });
      batch.commit();
   };
   editPerson = (
      cid,
      uid,
      person,
      oldLid,
      visitorsEnabled,
      routineEnabled,
      // grps
   ) => {
      console.log(
         person,
         // grps
      );
      // let index = null;
      // if (person.index) {
      //    index = person.index;
      //    delete person.index;
      // }
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      if (person.lid !== oldLid) {
         const oldLocPpl = this.db.doc(`location/${oldLid}`);
         const newLocPpl = this.db.doc(`location/${person.lid}`);
         batch.set(oldLocPpl, { people: decrement }, { merge: true });
         batch.set(newLocPpl, { people: increment }, { merge: true });
         const oldLocIndex = this.db.doc(`location/${oldLid}/indexes/people`);
         const newLocIndex = this.db.doc(`location/${person.lid}/indexes/people`);
         batch.set(oldLocIndex, { [uid]: deleteField }, { merge: true });
         batch.set(
            newLocIndex,
            {
               [uid]: {
                  name: {
                     firstName: person.name.firstName,
                     lastName: person.name.lastName,
                  },
               },
            },
            { merge: true },
         );
         if (visitorsEnabled) {
            const oldVisInt = this.db.doc(`location/${oldLid}/visitors/internalDetails`);
            batch.set(oldVisInt, { [person.email]: deleteField }, { merge: true });
         }
         if (routineEnabled) {
            const coRoutIndex = this.db.doc(`company/${cid}/indexes/routines`);
            batch.set(coRoutIndex, { [uid]: { lid: person.lid[0] } }, { merge: true });
         }
      }
      if (visitorsEnabled) {
         const visInt = this.db.doc(`location/${person.lid}/visitors/internalDetails`);
         batch.set(
            visInt,
            { [person.email]: `${person.name.firstName} ${person.name.lastName}` },
            { merge: true },
         );
      }
      if (person.status.statusId !== 'notInvited') {
         const user = this.db.doc(`users/${uid}`);
         batch.set(user, person, { merge: true });
      }
      const coUpdate = this.db.doc(`company/${cid}/people/${uid}`);
      batch.set(coUpdate, person, { merge: true });
      const coIndex = this.db.doc(`company/${cid}/indexes/people`);
      batch.set(
         coIndex,
         {
            [uid]: {
               email: person.email,
               name: { firstName: person.name.firstName, lastName: person.name.lastName },
            },
         },
         { merge: true },
      );
      const locIndex = this.db.doc(`location/${person.lid}/indexes/people`);
      batch.set(
         locIndex,
         {
            [uid]: {
               name: { firstName: person.name.firstName, lastName: person.name.lastName },
            },
         },
         { merge: true },
      );
      // let gIndex = {};
      // grps.new.map((gid) => {
      //    gIndex[gid] = { count: increment };
      //    const groupsRef = this.db.doc(`location/${oldLid}/groups/${gid}`);
      //    batch.set(
      //       groupsRef,
      //       {
      //          count: increment,
      //          members: {
      //             [uid]: {
      //                name: {
      //                   firstName: person.name.firstName,
      //                   lastName: person.name.lastName,
      //                },
      //             },
      //          },
      //       },
      //       { merge: true },
      //    );
      // });
      // grps.removed.map((gid) => {
      //    gIndex[gid] = { count: decrement };
      //    const groupsRef = this.db.doc(`location/${oldLid}/groups/${gid}`);
      //    batch.set(
      //       groupsRef,
      //       { count: decrement, members: { [uid]: deleteField } },
      //       { merge: true },
      //    );
      // });
      // const groupIndex = this.db.doc(`location/${oldLid}/indexes/groups`);
      // batch.set(groupIndex, gIndex, { merge: true });
      batch.commit();
   };
   saveSelfSignUpMSPerson = async (cid, user, data, visitorsEnabled) => {
      const batch = this.db.batch();
      const companyUpdate = this.db.doc(`company/${cid}/people/${user.uid}`);
      batch.set(companyUpdate, data, { merge: true });
      const userUpdate = this.db.collection('users').doc(`${user.uid}`);
      batch.set(userUpdate, data, { merge: true });
      const locUpdate = this.db.doc(`location/${data.lid}`);
      batch.set(
         locUpdate,
         { people: firebase.firestore.FieldValue.increment(1) },
         { merge: true },
      );
      const locIndex = this.db.doc(`location/${data.lid}/indexes/people`);
      batch.set(locIndex, { [user.uid]: { name: data.name } }, { merge: true });
      if (visitorsEnabled) {
         const locVis = this.db.doc(`location/${data.lid}/visitors/internalDetails`);
         batch.set(
            locVis,
            { [user.email]: `${data.name.firstName} ${data.name.lastName}` },
            { merge: true },
         );
      }
      await batch.commit();
   };

   //*** Visitors ***//
   findHost = (lid, date) => this.db.doc(`/location/${lid}/visitors/${date}`);
   allHosts = (lid) => this.db.doc(`/location/${lid}/visitors/internalDetails`);
   addVisitor = (cid, lid, uid, date, data) => {
      const batch = this.db.batch();
      const visitor = this.db.doc(`/location/${lid}/visitors/${date}`);
      batch.set(visitor, data, { merge: true });
      batch.set(
         visitor,
         { visitorNo: firebase.firestore.FieldValue.increment(1) },
         { merge: true },
      );
      const host = this.db.doc(`company/${cid}/people/${uid}/schedule/${date}`);
      batch.set(
         host,
         {
            lid: lid,
            visitorNo: firebase.firestore.FieldValue.increment(1),
            visitors: data,
         },
         { merge: true },
      );
      batch.commit();
   };
   deleteVisitor = (cid, lid, uid, date, email) => {
      const batch = this.db.batch();
      const visitor = this.db.doc(`/location/${lid}/visitors/${date}`);
      batch.set(
         visitor,
         { [email]: firebase.firestore.FieldValue.delete() },
         { merge: true },
      );
      batch.set(
         visitor,
         { visitorNo: firebase.firestore.FieldValue.increment(-1) },
         { merge: true },
      );
      const host = this.db.doc(`company/${cid}/people/${uid}/schedule/${date}`);
      batch.set(
         host,
         {
            visitorNo: firebase.firestore.FieldValue.increment(-1),
            visitors: { [email]: firebase.firestore.FieldValue.delete() },
         },
         { merge: true },
      );
      batch.commit();
   };
   visitorCheckin = (form, lid, date, action, unplanned, host) => {
      const batch = this.db.batch();
      const visitor = this.db.doc(`/location/${lid}/visitors/${date}`);
      if (unplanned) {
         batch.set(
            visitor,
            {
               [form.email]: { ...form, host, unplanned: true },
               visitorNo: firebase.firestore.FieldValue.increment(1),
            },
            { merge: true },
         );
      }
      let data = {
         [form.email]: {
            [action]: firebase.firestore.FieldValue.serverTimestamp(),
         },
      };
      if (host && host.checkout) {
         data[form.email].checkout = firebase.firestore.FieldValue.delete();
         data[form.email].previousCheckin = firebase.firestore.FieldValue.arrayUnion(
            host.checkin,
            host.checkout,
         );
      }
      if (action == 'checkin' && form.carReg) {
         data[form.email].carReg = form.carReg;
      }
      if (action == 'checkin' && form.contactNumber) {
         data[form.email].contactNumber = form.contactNumber;
      }
      batch.set(visitor, { ...data }, { merge: true });
      batch.commit();
   };
   getVisitorsCollection = (lid) => this.db.collection(`/location/${lid}/visitors`);
   deleteVisitorsCollection = (docs) => {};
   saveLocationAdmin = (uid, cid, locations, admins) => {
      const batch = this.db.batch();
      const user = this.db.doc(`users/${uid}`);
      batch.set(
         user,
         { roles: { ADMIN: 'ADMIN' }, locationAdmin: locations },
         { merge: true },
      );
      const coUser = this.db.doc(`company/${cid}/people/${uid}`);
      batch.set(
         coUser,
         { roles: { ADMIN: 'ADMIN' }, locationAdmin: locations },
         { merge: true },
      );
      locations.map((lid) => {
         const loc = this.db.doc(`location/${lid}`);
         batch.set(loc, { locationAdmins: admins }, { merge: true });
      });
      batch.commit();
   };
   deleteLocationAdmin = (uid, cid, locations, admins) => {
      const FieldValue = firebase.firestore.FieldValue;

      const batch = this.db.batch();
      const user = this.db.doc(`users/${uid}`);

      batch.set(
         user,
         { roles: { ADMIN: FieldValue.delete() }, locationAdmin: FieldValue.delete() },
         { merge: true },
      );
      const coUser = this.db.doc(`company/${cid}/people/${uid}`);
      batch.set(
         coUser,
         { roles: { ADMIN: FieldValue.delete() }, locationAdmin: FieldValue.delete() },
         { merge: true },
      );
      locations.map((lid) => {
         const loc = this.db.doc(`location/${lid}`);
         batch.set(loc, { locationAdmins: admins }, { merge: true });
      });
      batch.commit();
   };

   routineIndex = (cid) => this.db.doc(`company/${cid}/indexes/routines`);
   //*** spaces  *** //
   space = (lid) => this.db.collection(`location/${lid}/spaces`);
   spaces = (lid) => this.db.collection(`location/${lid}/spaces`);
   getSpace = (lid, sid) => this.db.collection(`location/${lid}/spaces`).doc(`${sid}`);
   getSpaceDetails = (lid, sids) =>
      this.db
         .collection(`location/${lid}/spaces`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', sids);
   getSpaceSchedules = (lid, sid) =>
      this.db.collection(`location/${lid}/spaces/${sid}/schedule`);
   getSpaceDaySchedule = (lid, sid, date) =>
      this.db.doc(`location/${lid}/spaces/${sid}/schedule/${date}`);
   getLocationAreas = (lid) =>
      this.db.collection(`location/${lid}/spaces`).where('type', '==', 'area');
   getChildSpaces = (lid, sid) =>
      this.db.collection(`location/${lid}/spaces`).where('parent', '==', sid);
   getUserSpaceBookingsOnDate = (cid, uid, date) =>
      this.db.collection(`company/${cid}/people/${uid}/schedule/`).doc(`${date}`);
   getLocalAvailableSpace = (lid, sids) =>
      this.db
         .collection(`location/${lid}/spaces`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', sids);
   getfavouriteSpaces = (lid, sids) =>
      this.db
         .collection(`location/${lid}/spaces`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', sids);
   spaceBookings = (lid, date) =>
      this.db.collection(`location/${lid}/spaceBookings/`).doc(`${date}`);
   futureSpaceBookings = (lid, date, sid) =>
      this.db
         .collection(`location/${lid}/spaces/${sid}/schedule`)
         .where(firebase.firestore.FieldPath.documentId(), '>=', date);
   spaceCheckIn = (lid, sid, date, bid) =>
      this.db.doc(`location/${lid}/spaces/${sid}/schedule/${date}`).set(
         {
            bookings: {
               [bid]: {
                  checkedIn: true,
                  checkInTime: firebase.firestore.FieldValue.serverTimestamp(),
               },
            },
         },
         { merge: true },
      );
   spaceCheckOut = (lid, sid, date, bid) =>
      this.db.doc(`location/${lid}/spaces/${sid}/schedule/${date}`).set(
         {
            bookings: {
               [bid]: {
                  checkedOut: true,
                  checkOutTime: firebase.firestore.FieldValue.serverTimestamp(),
               },
            },
         },
         { merge: true },
      );
   bookSpaceForUser = (lid, date, sid, form, status) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const spaceBooking = this.db
         .collection(`location/${lid}/spaceBookings/`)
         .doc(`${date}`);
      const bookingId = this.db.collection(`location/${lid}/spaceBookings/`).doc();
      let sb = {};
      sb = {
         [sid]: {
            bookingNo: increment,
            bookings: {
               [bookingId.id]: {
                  ownerId: form.user.uid,
                  status: 'confirmed',
                  type: form.typeName,
                  seats: form.seats,
               },
            },
         },
      };
      batch.set(spaceBooking, sb, { merge: true });
      const individualSpaceBooking = this.db
         .collection(`location/${lid}/spaces/${sid}/schedule`)
         .doc(`${date}`);
      let ib = {};
      ib = {
         bookingNo: increment,
         bookings: {
            [bookingId.id]: {
               ownerId: form.user.uid,
               type: form.typeName,
               status: 'confirmed',
               seats: form.seats,
            },
         },
      };
      batch.set(individualSpaceBooking, ib, { merge: true });
      const scheduleUpdate = this.db
         .collection(`location/${lid}/schedule`)
         .doc(`${date}`);
      const userScheduleUpdate = this.db
         .collection(`company/${form.user.cid}/people/${form.user.uid}/schedule`)
         .doc(`${date}`);
      let su = {};
      su = {
         [form.user.uid]: {
            bookingNo: increment,
            bookings: {
               [bookingId.id]: {
                  space: sid,
                  ownerId: form.user.uid,
                  type: form.typeName,
                  status: 'confirmed',
                  seat: form.seats,
               },
            },
         },
      };
      let usu = {};
      usu = {
         bookingNo: increment,
         bookings: {
            [bookingId.id]: {
               ownerId: form.user.uid,
               type: form.typeName,
               status: 'confirmed',
               seat: form.seats,
               lid,
               sid,
            },
         },
      };
      if (status.routine && status.routine === 'ito' && !status.overridden) {
         su[form.user.uid] = {
            ...su[form.user.uid],
            status: status.set,
            routine: 'ito',
            overridden: false,
         };
         usu = { ...usu, lid, status: status.set, routine: 'ito', overridden: false };
      }
      batch.set(scheduleUpdate, su, { merge: true });
      batch.set(userScheduleUpdate, usu, { merge: true });
      batch.commit();
   };

   bookMeetingForUser = (lid, date, sid, form) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const spaceBooking = this.db
         .collection(`location/${lid}/spaceBookings/`)
         .doc(`${date}`);

      let bookingId = this.db.collection(`location/${lid}/spaceBookings/`).doc().id;
      if (form.id !== null) {
         bookingId = form.id;
      }
      let sb = {};
      sb = {
         [sid]: {
            bookingNo: increment,
            bookings: {
               [bookingId]: {
                  ownerId: form.host.uid,
                  ...form,
               },
            },
         },
      };
      batch.set(spaceBooking, sb, { merge: true });
      const individualSpaceBooking = this.db
         .collection(`location/${lid}/spaces/${sid}/schedule`)
         .doc(`${date}`);
      let ib = {};
      ib = {
         bookingNo: increment,
         bookings: {
            [bookingId]: {
               ownerId: form.host.uid,
               ...form,
            },
         },
      };
      batch.set(individualSpaceBooking, ib, { merge: true });
      const scheduleUpdate = this.db
         .collection(`location/${lid}/schedule`)
         .doc(`${date}`);
      const userScheduleUpdate = this.db
         .collection(`company/${form.cid}/people/${form.host.uid}/schedule`)
         .doc(`${date}`);
      let su = {};
      su = {
         [form.host.uid]: {
            bookingNo: increment,
            bookings: {
               [bookingId]: {
                  space: sid,
                  ownerId: form.host.uid,
                  ...form,
               },
            },
         },
      };
      let usu = {};
      usu = {
         bookingNo: increment,
         bookings: {
            [bookingId]: {
               ownerId: form.host.uid,
               lid,
               sid,
               ...form,
            },
         },
      };

      batch.set(scheduleUpdate, su, { merge: true });
      batch.set(userScheduleUpdate, usu, { merge: true });
      batch.commit();
   };

   cancelBookedSpaceForUser = (data, type = false) => {
      let splitOffsite = false;
      if (!type && data.dayTime && data?.status?.splitDay[data.dayTime] === 'offsite') {
         splitOffsite = data.dayTime;
      }
      const FieldValue = firebase.firestore.FieldValue;
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const user = data.user;
      const date = data.date.ref;
      const batch = this.db.batch();
      Object.entries(data.space).map(([key, value]) => {
         if (key !== 'bookingNo') {
            let bid = key;
            let lid = value.lid;
            let sid = value.sid;
            const spaceBooking = this.db
               .collection(`location/${lid}/spaceBookings/`)
               .doc(`${date}`);
            const spaceScheduleUpdate = this.db
               .collection(`location/${lid}/spaces/${sid}/schedule`)
               .doc(`${date}`);
            const scheduleUpdate = this.db
               .collection(`location/${lid}/schedule`)
               .doc(`${date}`);
            const userScheduleUpdate = this.db
               .collection(`company/${user.cid}/people/${user.uid}/schedule`)
               .doc(`${date}`);
            let sb = {};
            let ss = {};
            let su = {};
            let usu = {};
            sb = {
               [sid]: {
                  bookingNo: decrement,
                  bookings: {
                     [bid]: FieldValue.delete(),
                  },
               },
            };
            ss = {
               bookingNo: decrement,
               bookings: {
                  [bid]: FieldValue.delete(),
               },
            };
            su = {
               [user.uid]: {
                  bookingNo: decrement,
                  bookings: {
                     [bid]: FieldValue.delete(),
                  },
               },
            };
            usu = {
               bookingNo: decrement,
               bookings: {
                  [bid]: FieldValue.delete(),
               },
            };
            if (
               (!type && !splitOffsite) ||
               (type === 'split' && (value.type == 'All day' || value.type == 'PM')) ||
               (type === 'merge' && value.type == 'PM') ||
               type === value.type ||
               splitOffsite === value.type
            ) {
               batch.set(spaceBooking, sb, { merge: true });
               batch.set(spaceScheduleUpdate, ss, { merge: true });
               batch.set(scheduleUpdate, su, { merge: true });
               batch.set(userScheduleUpdate, usu, { merge: true });
            }
         }
      });
      batch.commit();
   };

   deleteSpaceImage = (lid, sid) => {
      const FieldValue = firebase.firestore.FieldValue;
      const batch = this.db.batch();
      const spaceToUpdate = this.db.collection(`location/${lid}/spaces`).doc(`${sid}`);
      batch.update(spaceToUpdate, { imagePath: FieldValue.delete() });
      batch.commit();
   };
   deleteSpaceMap = (lid, sid) => {
      const FieldValue = firebase.firestore.FieldValue;
      const batch = this.db.batch();
      const spaceToUpdate = this.db.collection(`location/${lid}/spaces`).doc(`${sid}`);
      batch.update(spaceToUpdate, { mapPath: FieldValue.delete() });
      batch.commit();
   };
   deleteSpaceSchedule = (lid, day, space) => {
      //not finished yet.
      const FieldValue = firebase.firestore.FieldValue;
      const batch = this.db.batch();
      if (day.bookingNo != 0) {
         const scheduleRef = this.db.doc(`location/${lid}/schedule/${day.id}`);
      } else {
         const spaceBookingsRef = this.db.doc(`location/${lid}/spaceBookings/${day.id}`);
         batch.set(
            spaceBookingsRef,
            { [space.id]: FieldValue.delete() },
            { merge: true },
         );
         const spaceScheduleRef = this.db.doc(
            `location/${lid}/spaces/${space.id}/schedule/${day.id}`,
         );
         batch.delete(spaceScheduleRef);
      }
      // batch.commit();
      return;
   };

   archiveSpace = (lid, sid) =>
      this.db
         .collection(`location/${lid}/spaces`)
         .doc(`${sid}`)
         .set({ archive: true }, { merge: true });
   unarchiveSpace = (lid, sid) =>
      this.db
         .collection(`location/${lid}/spaces`)
         .doc(`${sid}`)
         .set({ archive: false }, { merge: true });

   // *** groups *** //
   groups = (cid) => this.db.collection(`company/${cid}/groups`);
   group = (cid, gid) => this.db.collection(`company/${cid}/groups`).doc(`${gid}`);
   groupPeople = (cid, gid) =>
      this.db.collection(`company/${cid}/people/`).where('gid', 'array-contains', gid);
   groupPerson = (lid, gid, uid) =>
      this.db.doc(`location/${lid}/groups/${gid}/people/${uid}`);

   // *** Global Groups Admin Index stuff ***//
   cleanUpGlobalData = async (locationsArray, lid, gid) => {
      const batch = this.db.batch();
      try {
         locationsArray.map((locationId) => {
            if (locationId === lid) return;
            this.deleteLocationGroup(locationId, gid);
         });
         batch.commit();
      } catch (err) {
         console.error(err, 'error saving group');
      }
   };
   saveGlobalGroup = async (locationsArray, gid, group) => {
      locationsArray.map(async (lid) => await this.saveLocationGroup(lid, gid, group));
   };
   saveLocationGroup = (lid, gid, group) => {
      const batch = this.db.batch();
      const groupRef = this.db.doc(`location/${lid}/groups/${gid}`);
      const groupIndexRef = this.db.doc(`location/${lid}/indexes/groups`);

      const groupIndexObject = { ...group };

      delete groupIndexObject.members;
      delete groupIndexObject.admins;
      delete groupIndexObject.gid;

      try {
         batch.set(groupRef, group, { merge: false });
         batch.set(groupIndexRef, { [gid]: groupIndexObject }, { merge: true });
         batch.commit();
      } catch (err) {
         console.error(err, 'error saving group');
      }
   };
   saveGroup = async (lid, gid, group, locations) => {
      const locationsArray = Object.keys(locations);
      if (group.global) {
         await this.saveGlobalGroup(locationsArray, gid, group);
         return;
      }
      await this.saveLocationGroup(lid, gid, group);
      await this.cleanUpGlobalData(locationsArray, lid, gid);
      return;
   };

   deleteGlobalGroup = async (cid, gid) => {
      const companyRef = this.db.doc(`company/${cid}`);
      try {
         return await this.db.runTransaction(async (t) => {
            const company = await t.get(companyRef);
            const lidArray = Object.keys(company.data().locations);

            lidArray.map(async (lid) => await this.deleteLocationGroup(lid, gid));
            return;
         });
      } catch (error) {
         console.warn(error, 'Error saving global group change');
      }
   };
   deleteLocationGroup = (lid, gid) => {
      const batch = this.db.batch();
      const groupRef = this.db.doc(`location/${lid}/groups/${gid}`);
      const groupIndexRef = this.db.doc(`location/${lid}/indexes/groups`);
      const deleteField = firebase.firestore.FieldValue.delete();

      try {
         batch.delete(groupRef);
         batch.set(groupIndexRef, { [gid]: deleteField }, { merge: true });
         batch.commit();
      } catch (err) {
         console.error(err, 'error deleting group');
      }
   };
   deleteGroupIdFromPeople = async (cid, gid, memberArray) => {
      const batch = this.db.batch();
      const arrayRemove = (value) => firebase.firestore.FieldValue.arrayRemove(value);

      try {
         memberArray.map((personId) => {
            const personRef = this.db.doc(`company/${cid}/people/${personId}`);
            batch.update(
               personRef,
               {
                  gid: arrayRemove(gid),
                  groupAdmins: arrayRemove(gid),
               },
               { merge: true },
            );
         });
         batch.commit();
      } catch (err) {
         console.error(err, 'error deleting group');
      }
   };
   deleteGroup = async (cid, lid, gid, group) => {
      if (group.global) {
         await this.deleteGlobalGroup(cid, gid, group);
         return;
      }
      await this.deleteLocationGroup(lid, gid, group);
      return;
   };
   getAdminIndex = async (cid, uid) => {
      const userAdminIndexRef = this.db.doc(
         `company/${cid}/people/${uid}/index/groupAdmin`,
      );
      try {
         const response = await userAdminIndexRef
            .get()
            .then((result) => (result.exists ? result.data() : null));
         return response;
      } catch (err) {
         console.error;
      }
   };
   processGroupMember = async (cid, job, action) => {
      const { uid, gid, isAdmin } = job;
      const batch = this.db.batch();
      const userRef = this.db.doc(`company/${cid}/people/${uid}`);
      const userMainRef = this.db.doc(`users/${uid}`);
      try {
         const userDetails = (await userRef.get()).data();
         const userIndex = await this.getAdminIndex(cid, uid);
         let groupAdmin = userIndex && Object.keys(userIndex).length > 0;
         const template = {
            gid: [],
            groupAdmins: [],
         };
         if (action === 'update') {
            let newUserDetails = {
               ...updateGroupObject(
                  {
                     ...template,
                     ...userDetails,
                  },
                  gid,
                  isAdmin,
               ),
               roles: {
                  groupAdmin,
               },
            };
            batch.set(userRef, newUserDetails, { merge: true });
            batch.set(userMainRef, newUserDetails, { merge: true });
            batch.commit();
            return;
         } else {
            let newUserDetails = {
               ...removeGroupObject(userDetails, gid, isAdmin),
               roles: {
                  groupAdmin,
               },
            };
            batch.set(userRef, newUserDetails, { merge: true });
            batch.set(userMainRef, newUserDetails, { merge: true });
            batch.commit();
            return;
         }
      } catch (err) {
         console.error(
            err,
            `error processing the group member ${uid} for group ${gid}. function: processGroupMember`,
         );
      }
   };
   processAdminMembers = async (cid, job, action) => {
      const { uid, members } = job;
      const batch = this.db.batch();

      const userIndex = await this.getAdminIndex(cid, uid);
      const newUserIndex = createModifiedIndex(
         userIndex,
         members.previous,
         members.current,
         action,
      );

      const userIndexRef = this.db.doc(`company/${cid}/people/${uid}/index/groupAdmin`);
      batch.set(userIndexRef, newUserIndex);
      batch.commit();
   };
   updateGroupMembers = async (cid, gid, admins, members) => {
      const adminTasks = admins.current.reduce(
         (acc, memberUid) => [
            ...acc,
            {
               uid: memberUid,
               admins,
               members,
            },
         ],
         [],
      );
      const removedAdminTasks = admins.removed.reduce(
         (acc, memberUid) => [
            ...acc,
            {
               uid: memberUid,
               admins,
               members,
            },
         ],
         [],
      );
      const removedMemberTasks = members.removed.reduce(
         (acc, memberUid) => [
            ...acc,
            {
               uid: memberUid,
               isAdmin: admins.current.includes(memberUid),
               gid,
               previousMembers: members.previous,
               currentMembers: [],
            },
         ],
         [],
      );
      const currentMemberTasks = members.current.reduce(
         (acc, memberUid) => [
            ...acc,
            {
               uid: memberUid,
               isAdmin: admins.current.includes(memberUid),
               gid,
               previousMembers: members.previous,
               currentMembers: members.current,
            },
         ],
         [],
      );
      await adminTasks.map(
         async (job) => await this.processAdminMembers(cid, job, 'update'),
      );
      await removedAdminTasks.map(
         async (job) => await this.processAdminMembers(cid, job, 'remove'),
      );
      await currentMemberTasks.map(
         async (job) => await this.processGroupMember(cid, job, 'update'),
      );
      await removedMemberTasks.map(
         async (job) => await this.processGroupMember(cid, job, 'remove'),
      );
   };

   // *** Schedule stuff ***//
   personScheduleExists = (cid, uid) =>
      this.db.collection(`company/${cid}/people/${uid}/schedule`);
   personFutureScheduleExists = (cid, uid, date) =>
      this.db
         .collection(`company/${cid}/people/${uid}/schedule`)
         .where(firebase.firestore.FieldPath.documentId(), '>', date);
   companyWeekSchedule = (cid, date) =>
      this.db.doc(`company/${cid}/schedule/${date.year}/${date.month}`);

   locationDaySchedule = (lid, date) =>
      this.db
         .collection(`location/${lid}/schedule`)
         .doc(`${date.year}${date.month}${date.day}`);
   locationDayScheduleDate = (lid, date) =>
      this.db.collection(`location/${lid}/schedule`).doc(`${date}`);

   locationScheduleDayStreak = (lid, dates) =>
      this.db
         .collection(`location/${lid}/schedule/`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', dates);
   locationScheduleMultiDay = (lid, start, end) =>
      this.db
         .collection(`location/${lid}/schedule/`)
         .where(firebase.firestore.FieldPath.documentId(), '>=', start)
         .where(firebase.firestore.FieldPath.documentId(), '<=', end);
   // .orderByKey().startAt(`${start}`).endAt(`${end}`);
   userLocationScheduleDayStreak = (lid, uid, dates) =>
      this.db
         .collection(`location/${lid}/people/${uid}/schedule/`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', dates);
   userScheduleDayStreak = (cid, uid, dates) =>
      this.db
         .collection(`company/${cid}/people/${uid}/schedule/`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', dates);
   userScheduleDay = (cid, uid, date) =>
      this.db.doc(`company/${cid}/people/${uid}/schedule/${date}`);

   userScheduleMultiDay = (uid, cid, start, end) =>
      this.db
         .collection(`company/${cid}/people/${uid}/schedule/`)
         .where(firebase.firestore.FieldPath.documentId(), '>=', start)
         .where(firebase.firestore.FieldPath.documentId(), '<=', end);
   locationStatusLevels = (lid, start, end) =>
      this.db
         .collection(`location/${lid}/statusLevels/`)
         .where(firebase.firestore.FieldPath.documentId(), '>=', start)
         .where(firebase.firestore.FieldPath.documentId(), '<=', end);

   daySplit = (data, status, displayFilter) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const statusLevel = this.db
         .collection(`location/${displayFilter.id}/statusLevels/`)
         .doc(`${data.date.ref}`);
      const userSched = this.db.doc(
         `company/${data.user.cid}/people/${data.user.uid}/schedule/${data.date.ref}`,
      );
      const locationSched = this.db
         .collection(`location/${displayFilter.id}/schedule`)
         .doc(`${data.date.ref}`);
      let sl = {
         AM: {
            [status.set]: increment,
         },
         PM: {
            ['-']: increment,
         },
      };
      let us = {
         status: 'split',
         splitDay: {
            AM: status.set,
            PM: '-',
         },
         lid: displayFilter.id,
      };
      let ls = {
         [data.user.uid]: {
            status: 'split',
            splitDay: {
               AM: status.set,
               PM: '-',
            },
         },
      };
      if (status.offsiteLocation) {
         if (status.offsiteLocation.locationId) {
            us.offsiteLocation = {
               AM: { ...status.offsiteLocation },
               locationId: deleteField,
               locationName: deleteField,
            };
            ls[data.user.uid].offsiteLocation = {
               AM: { ...status.offsiteLocation },
               locationId: deleteField,
               locationName: deleteField,
            };
            const offsiteSL = this.db
               .collection(`location/${status.offsiteLocation.locationId}/statusLevels/`)
               .doc(`${data.date.ref}`);
            batch.set(
               offsiteSL,
               { offsiteIto: decrement, AM: { offsiteIto: increment } },
               { merge: true },
            );
         } else {
            us.offsiteLocation = {
               AM: status.offsiteLocation,
            };
            ls[data.user.uid].offsiteLocation = {
               AM: status.offsiteLocation,
            };
         }
      }
      if (status.routine && status.routine !== '-' && !status.overridden) {
         sl = { ...sl, overridden: { [status.routine]: increment } };
         us = { ...us, routine: status.routine, overridden: true };
         ls[data.user.uid] = {
            ...ls[data.user.uid],
            routine: status.routine,
            overridden: true,
         };
      } else {
         sl = { ...sl, [status.set]: decrement };
      }
      batch.set(statusLevel, sl, { merge: true });
      batch.set(userSched, us, { merge: true });
      batch.set(locationSched, ls, { merge: true });
      batch.commit();
   };
   dayMerge = (data, status, displayFilter) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const statusLevel = this.db
         .collection(`location/${displayFilter.id}/statusLevels/`)
         .doc(`${data.date.ref}`);
      const userSched = this.db.doc(
         `company/${data.user.cid}/people/${data.user.uid}/schedule/${data.date.ref}`,
      );
      const locationSched = this.db
         .collection(`location/${displayFilter.id}/schedule`)
         .doc(`${data.date.ref}`);
      let sl = {
         AM: {
            [status.splitDay.AM]: decrement,
         },
         PM: {
            [status.splitDay.PM]: decrement,
         },
      };
      let us = {
         status: status.splitDay.AM,
         splitDay: deleteField,
      };
      let ls = {
         [data.user.uid]: {
            status: status.splitDay.AM,
            splitDay: deleteField,
         },
      };
      if (status.offsiteLocation) {
         if (status.offsiteLocation.PM && !status.offsiteLocation.AM) {
            us.offsiteLocation = deleteField;
            ls[data.user.uid].offsiteLocation = deleteField;
            if (status.offsiteLocation.PM.locationId) {
               const offsiteLoc = this.db
                  .collection(
                     `location/${status.offsiteLocation.PM.locationId}/statusLevels/`,
                  )
                  .doc(`${data.date.ref}`);
               batch.set(offsiteLoc, { PM: { offsiteIto: decrement } }, { merge: true });
            }
         } else if (status.offsiteLocation.PM) {
            us.offsiteLocation = {
               PM: deleteField,
            };
            ls[data.user.uid].offsiteLocation = {
               PM: deleteField,
            };
            if (status.offsiteLocation.PM.locationId) {
               const offsiteLoc = this.db
                  .collection(
                     `location/${status.offsiteLocation.PM.locationId}/statusLevels/`,
                  )
                  .doc(`${data.date.ref}`);
               batch.set(offsiteLoc, { PM: { offsiteIto: decrement } }, { merge: true });
            }
         }
         if (status.offsiteLocation.AM) {
            if (status.offsiteLocation.AM.locationId) {
               us.offsiteLocation = {
                  ...us.offsiteLocation,
                  AM: deleteField,
                  locationId: status.offsiteLocation.AM.locationId,
                  locationName: status.offsiteLocation.AM.locationName,
               };
               ls[data.user.uid].offsiteLocation = {
                  ...ls[data.user.uid].offsiteLocation,
                  AM: deleteField,
                  locationId: status.offsiteLocation.AM.locationId,
                  locationName: status.offsiteLocation.AM.locationName,
               };
               const offsiteSL = this.db
                  .collection(
                     `location/${status.offsiteLocation.AM.locationId}/statusLevels/`,
                  )
                  .doc(`${data.date.ref}`);
               batch.set(
                  offsiteSL,
                  { AM: { offsiteIto: decrement }, offsiteIto: increment },
                  { merge: true },
               );
            } else {
               us.offsiteLocation = status.offsiteLocation.AM;
               ls[data.user.uid].offsiteLocation = status.offsiteLocation.AM;
            }
         }
      }
      if (status.routine && status.routine !== '-') {
         if (status.routine === status.splitDay.AM) {
            sl = { ...sl, overridden: { [status.routine]: decrement } };
            us = { ...us, overridden: false };
            ls[data.user.uid] = { ...ls[data.user.uid], overridden: false };
         } else {
            sl = { ...sl, [status.splitDay.AM]: increment };
         }
      } else {
         sl = { ...sl, [status.splitDay.AM]: increment };
      }
      batch.set(statusLevel, sl, { merge: true });
      batch.set(userSched, us, { merge: true });
      batch.set(locationSched, ls, { merge: true });
      batch.commit();
   };
   saveCleanRoutine = async (user, oldStatus, status) => {
      const batch = this.db.batch();
      let timeStamp = Date.now();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      let routine = {};
      let oldRoutine = {};
      for (var i = 0; i < 7; i++) {
         routine[i] = status[i].set;
         oldRoutine[i] = oldStatus[i].set;
      }
      const userRoutine = this.db.doc(`users/${user.id}`);
      batch.set(userRoutine, { routine }, { merge: true });
      const userRoutineHistory = this.db.doc(`users/${user.id}/history/routines`);
      batch.set(userRoutineHistory, { [timeStamp]: routine }, { merge: true });
      const coRoutine = this.db.doc(`company/${user.cid}/people/${user.id}`);
      batch.set(coRoutine, { routine }, { merge: true });
      const coRoutIndex = this.db.doc(`company/${user.cid}/indexes/routines`);
      batch.set(
         coRoutIndex,
         { [user.id]: { lid: user.lid[0], routine } },
         { merge: true },
      );
      const locRoutine = this.db.doc(`location/${user.lid[0]}/statusLevels/routine`);
      Object.entries(routine).map(([key, value]) => {
         batch.set(locRoutine, { [key]: { [value]: increment } }, { merge: true });
      });
      if (oldRoutine !== false) {
         Object.entries(oldRoutine).map(([key, value]) => {
            batch.set(locRoutine, { [key]: { [value]: decrement } }, { merge: true });
         });
      }
      batch.commit();
   };
   underwriteRoutine = async (routine) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      Object.entries(routine).map(([key, value]) => {
         if (value.lid === value.lidOld) {
            const statusLevel = this.db
               .collection(`location/${value.lid}/statusLevels/`)
               .doc(`${key}`);
            const locationStatus = this.db
               .collection(`location/${value.lid}/schedule`)
               .doc(`${key}`);
            const personStatus = this.db
               .collection(`company/${value.cid}/people/${value.uid}/schedule`)
               .doc(`${key}`);
            let ls = {};
            let ps = {};
            let sl = {};
            if (value.type === 'new') {
               ls = {
                  [value.uid]: { overridden: value.overridden, routine: value.routine },
               };
               ps = { overridden: value.overridden, routine: value.routine };
               sl = { [value.status]: decrement };
               if (value.overridden) {
                  sl = { overridden: { [value.routine]: increment } };
               }
            }
            if (value.type === 'update') {
               ls = {
                  [value.uid]: { overridden: value.overridden, routine: value.routine },
               };
               ps = { overridden: value.overridden, routine: value.routine };
               sl = {
                  overridden: {
                     [value.oldRoutine]: decrement,
                     [value.routine]: increment,
                  },
               };
               if (!value.overridden) {
                  sl = {
                     [value.status]: decrement,
                     overridden: { [value.oldRoutine]: decrement },
                  };
               }
               if (!value.oldOverridden) {
                  sl = {
                     [value.status]: increment,
                     overridden: { [value.routine]: increment },
                  };
               }
            }
            if (value.type === 'remove') {
               ls = { [value.uid]: { overridden: deleteField, routine: deleteField } };
               ps = { overridden: deleteField, routine: deleteField };
               sl = { [value.status]: increment };
               if (value.overridden) {
                  sl = { overridden: { [value.oldRoutine]: decrement } };
               }
            }
            if (
               value.type === 'new' ||
               value.type === 'update' ||
               value.type === 'remove'
            ) {
               batch.set(statusLevel, sl, { merge: true });
               batch.set(locationStatus, ls, { merge: true });
               batch.set(personStatus, ps, { merge: true });
            }
         } else {
            const statusLevelOld = this.db
               .collection(`location/${value.lidOld}/statusLevels/`)
               .doc(`${key}`);
            let slOld = { [value.status]: decrement };
            batch.set(statusLevelOld, slOld, { merge: true });
            const locationStatusOld = this.db
               .collection(`location/${value.lidOld}/schedule`)
               .doc(`${key}`);
            let lsOld = { [value.uid]: deleteField };
            batch.set(locationStatusOld, lsOld, { merge: true });
            const statusLevelNew = this.db
               .collection(`location/${value.lid}/statusLevels/`)
               .doc(`${key}`);
            const locationStatusNew = this.db
               .collection(`location/${value.lid}/schedule`)
               .doc(`${key}`);
            const personStatus = this.db
               .collection(`company/${value.cid}/people/${value.uid}/schedule`)
               .doc(`${key}`);
            const schedule = {
               ...value.schedule,
               lid: value.lid,
               overridden: value.overridden,
               routine: value.routine,
            };
            let ps = schedule;
            let amendedSchedule = {
               ...value.schedule,
               overridden: value.overridden,
               routine: value.routine,
            };
            delete amendedSchedule.lid;
            let ls = { [value.uid]: amendedSchedule };
            let sl = {};
            sl = { [value.status]: increment };
            if (value.overridden) {
               sl = { ...sl, overridden: { [value.routine]: increment } };
            }
            batch.set(statusLevelNew, sl, { merge: true });
            batch.set(locationStatusNew, ls, { merge: true });
            batch.set(personStatus, ps, { merge: true });
         }
      });
      batch.commit();
   };
   migrateFutureSchedule = async (user, uid, futureSchedule, displayFilter, newLid) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();

      futureSchedule.forEach((result) => {
         let date = result.id;
         let day = result.data();
         const oldLid = day.lid;
         const personStatus = this.db
            .collection(`company/${user.cid}/people/${uid}/schedule`)
            .doc(`${date}`);
         const statusLevelOld = this.db
            .collection(`location/${oldLid}/statusLevels/`)
            .doc(`${date}`);
         const locationStatusOld = this.db
            .collection(`location/${oldLid}/schedule`)
            .doc(`${date}`);
         const statusLevelNew = this.db
            .collection(`location/${newLid}/statusLevels/`)
            .doc(`${date}`);
         const locationStatusNew = this.db
            .collection(`location/${newLid}/schedule`)
            .doc(`${date}`);
         let oldStatus = day.status;
         let newStatus = day.status;
         let offsiteLocation = '';
         if (day.offsiteLocation && day.offsiteLocation.locationId !== newLid) {
            offsiteLocation = day.offsiteLocation;
         }
         let ps = { lid: newLid };
         let slOld = {};
         let slNew = {};
         let lsOld = { [uid]: deleteField };
         batch.set(locationStatusOld, lsOld, { merge: true });
         let lsNew = { [uid]: day };
         delete lsNew[uid].lid;

         if (day.status === 'ito') {
            newStatus = 'offsite';
            offsiteLocation = {
               locationId: oldLid,
               locationName: displayFilter.title,
            };
            slOld['offsiteIto'] = increment;
            if (day.bookingNo > 0) {
               let lsOld = {
                  [uid]: { bookingNo: day.bookingNo, bookings: day.bookings },
               };
               batch.set(locationStatusOld, lsOld, { merge: true });
               lsNew[uid] = { bookingNo: 0, bookings: {} };
            }
         }
         if (day.status === 'offsite' && day.offsiteLocation.locationId === newLid) {
            newStatus = 'ito';
            slNew['offsiteIto'] = decrement;
         }
         ps.status = newStatus;
         ps.offsiteLocation = offsiteLocation;
         slOld[oldStatus] = decrement;
         slNew[newStatus] = increment;
         if (day.status === 'split') {
            ps.splitDay = day.splitDay;
            slOld = { AM: {}, PM: {} };
            slNew = { AM: {}, PM: {} };
            Object.entries(day.splitDay).map(([key, value]) => {
               if (value === 'ito') {
                  ps.splitDay[key] = 'offsite';
                  slOld[key]['offsiteIto'] = increment;

                  if (ps.offsiteLocation === '') {
                     ps.offsiteLocation = {};
                  }
                  ps.offsiteLocation[key] = {
                     locationId: oldLid,
                     locationName: displayFilter.title,
                  };
               }
               if (
                  value === 'offsite' &&
                  ps.offsiteLocation[key] &&
                  ps.offsiteLocation[key].locationId === newLid
               ) {
                  ps.splitDay[key] = 'ito';
                  ps.offsiteLocation[key] = '';
                  slNew[key]['offsiteIto'] = decrement;
               }
               slOld[key][value] = decrement;
               slNew[key][ps.splitDay[key]] = increment;
               lsNew[uid].splitDay[key] = ps.splitDay[key];
            });

            if (day.bookingNo > 0) {
               let oldBookings = {};
               let oldBookingNo = 0;
               let newBookings = {};
               let newBookingNo = 0;
               Object.entries(day.bookings).map(([key, value]) => {
                  if (value.lid === newLid) {
                     newBookings[key] = value;
                     delete newBookings[key].lid;
                     newBookingNo = newBookingNo + 1;
                  }
                  if (value.lid === oldLid) {
                     oldBookings[key] = value;
                     delete oldBookings[key].lid;
                     oldBookingNo = oldBookingNo + 1;
                  }
               });
               lsOld[uid] = { bookingNo: oldBookingNo, bookings: oldBookings };
               batch.set(locationStatusOld, lsOld, { merge: true });
               lsNew[uid].bookingNo = newBookingNo;
               lsNew[uid].bookings = newBookings;
            }
         }
         lsNew[uid].status = newStatus;
         lsNew[uid].offsiteLocation = offsiteLocation;
         batch.set(personStatus, ps, { merge: true });
         batch.set(statusLevelOld, slOld, { merge: true });
         batch.set(statusLevelNew, slNew, { merge: true });
         batch.set(locationStatusNew, lsNew, { merge: true });

         if (day.visitorNo > 0) {
            Object.entries(day.visitors).map(([key, value]) => {
               const locationVisOld = this.db
                  .collection(`location/${oldLid}/visitors`)
                  .doc(`${date}`);
               const locationVisNew = this.db
                  .collection(`location/${newLid}/visitors`)
                  .doc(`${date}`);
               batch.set(
                  locationVisOld,
                  { [key]: deleteField, visitorNo: decrement },
                  { merge: true },
               );
               batch.set(
                  locationVisNew,
                  { [key]: value, visitorNo: increment },
                  { merge: true },
               );
            });
         }
      });
      batch.commit();
   };
   customTimingsSave = (times, user, status, date, routine) => {
      const batch = this.db.batch();
      const userSched = this.db
         .collection(`company/${user.cid}/people/${user.uid}/schedule`)
         .doc(`${date}`);
      const locationSched = this.db
         .collection(`location/${user.lid}/schedule`)
         .doc(`${date}`);
      let us = { customTimes: true, times };
      let locSchedUser = { [user.uid]: { customTimes: true, times } };
      if (routine) {
         locSchedUser[user.uid] = {
            ...locSchedUser[user.uid],
            status: status.set,
            routine: status.routine,
            overridden: false,
         };
         us = {
            ...us,
            lid: user.lid,
            status: status.set,
            routine: status.routine,
            overridden: false,
         };
      }
      batch.set(userSched, us, { merge: true });
      batch.set(locationSched, locSchedUser, { merge: true });
      batch.commit();
   };
   customTimingsDelete = (user, status, date) => {
      const deleteField = firebase.firestore.FieldValue.delete();
      const batch = this.db.batch();
      const userSched = this.db
         .collection(`company/${user.cid}/people/${user.uid}/schedule`)
         .doc(`${date}`);
      const locationSched = this.db
         .collection(`location/${status.lid}/schedule`)
         .doc(`${date}`);
      batch.set(userSched, { customTimes: false, times: deleteField }, { merge: true });
      batch.set(
         locationSched,
         { [user.uid]: { customTimes: false, times: deleteField } },
         { merge: true },
      );
      batch.commit();
   };

   //*** Set ITO Status ***/
   setStatus = async (user, date, status, oldStatus, locations, routine) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const instructions = this.db.doc(`location/${locations.new}`);
      batch.set(instructions, { setup: { status: true } }, { merge: true });

      const statusLevelOld = this.db
         .collection(`location/${locations.old}/statusLevels/`)
         .doc(`${date}`);
      const statusLevelNew = this.db
         .collection(`location/${locations.new}/statusLevels/`)
         .doc(`${date}`);
      let sl = { [status]: increment, [oldStatus]: decrement };
      let slOld = { [oldStatus]: decrement };
      let slNew = { [status]: increment };

      const locationStatusOld = this.db
         .collection(`location/${locations.old}/schedule`)
         .doc(`${date}`);
      const locationStatusNew = this.db
         .collection(`location/${locations.new}/schedule`)
         .doc(`${date}`);
      let lsOld = { [user.uid]: { status: deleteField } };
      let lsNew = { [user.uid]: { status } };

      const personStatus = this.db
         .collection(`company/${user.cid}/people/${user.uid}/schedule`)
         .doc(`${date}`);
      let ps = { status: status, lid: locations.new };

      if (routine && routine.status !== '-') {
         if (!routine.overridden) {
            slNew = { overridden: { [routine.status]: decrement } };
            lsNew[user.uid] = { ...lsNew[user.uid], overridden: false };
            ps = { ...ps, overridden: false };
            batch.set(personStatus, ps, { merge: true });
         } else if (routine.overridden && oldStatus === routine.status) {
            slOld = false;
            slNew = { [status]: increment, overridden: { [routine.status]: increment } };
            lsNew[user.uid] = {
               ...lsNew[user.uid],
               overridden: true,
               routine: routine.status,
            };
            ps = { ...ps, overridden: true, routine: routine.status };
            batch.set(personStatus, ps, { merge: true });
         } else {
            lsNew[user.uid] = {
               ...lsNew[user.uid],
               overridden: true,
               routine: routine.status,
            };
            batch.set(personStatus, ps, { merge: true });
         }
      } else {
         batch.set(personStatus, ps, { merge: true });
      }
      if (slOld) {
         batch.set(statusLevelOld, slOld, { merge: true });
      }
      batch.set(statusLevelNew, slNew, { merge: true });
      batch.set(locationStatusOld, lsOld, { merge: true });
      batch.set(locationStatusNew, lsNew, { merge: true });
      batch.commit();
   };

   setHalfDay = (data, location, oldStatus, newStatus, dayTime) => {
      const batch = this.db.batch();
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const statusLevel = this.db
         .collection(`location/${location.id}/statusLevels/`)
         .doc(`${data.date.ref}`);
      let sl = { [dayTime]: { [newStatus]: increment, [oldStatus]: decrement } };
      batch.set(statusLevel, sl, { merge: true });
      const locationStatus = this.db
         .collection(`location/${location.id}/schedule`)
         .doc(`${data.date.ref}`);
      const ls = {
         [data.user.uid]: {
            splitDay: {
               [dayTime]: newStatus,
            },
         },
      };
      batch.set(locationStatus, ls, { merge: true });
      const personStatus = this.db
         .collection(`company/${data.user.cid}/people/${data.user.uid}/schedule`)
         .doc(`${data.date.ref}`);

      const ps = {
         splitDay: {
            [dayTime]: newStatus,
         },
      };
      batch.set(personStatus, ps, { merge: true });
      batch.commit();
   };

   setStatusBulk = async (form) => {
      const batch = this.db.batch();
      var d = ('0' + form.date.getDate()).slice(-2);
      var m = ('0' + form.date.getMonth()).slice(-2);
      const date = form.date.getFullYear() + m + d;
      var increaseStatus = 0;
      var decreaseStatus = 0;
      const locationStatus = this.db
         .collection(`location/${form.locationId}/schedule`)
         .doc(`${date}`);
      const statusLevel = this.db.doc(`location/${form.locationId}/statusLevels/${date}`);
      Object.entries(form.internalParticipants).map(async ([key, value]) => {
         const personStatus = this.db
            .collection(`company/${form.cid}/people/${key}/schedule`)
            .doc(`${date}`);
         if (!value.routine || (value.routine && value.routine === '-')) {
            if (value.status === 'none' || value.status === '-') {
               increaseStatus = increaseStatus + 1;
               let ls = {
                  [key]: {
                     status: 'ito',
                  },
               };
               let ps = {
                  status: 'ito',
                  lid: form.locationId,
               };
               batch.set(locationStatus, ls, { merge: true });
               batch.set(personStatus, ps, { merge: true });
            }
            if (value.status === '-') {
               decreaseStatus = decreaseStatus - 1;
            }
         }
         if (value.routine && value.status === '-') {
            increaseStatus = increaseStatus + 1;
            decreaseStatus = decreaseStatus - 1;
            let ls = {
               [key]: {
                  status: 'ito',
               },
            };
            let ps = {
               status: 'ito',
               lid: key,
            };
            if (value.routine === 'ito') {
               increaseStatus = increaseStatus - 1;
               ls[key] = { ...ls[key], overridden: false, routine: value.routine };
               ps = { ...ps, overridden: false, routine: value.routine };
               if (value.status === '-') {
                  batch.set(
                     statusLevel,
                     {
                        overridden: { ito: firebase.firestore.FieldValue.increment(-1) },
                     },
                     { merge: true },
                  );
               }
            }
            batch.set(locationStatus, ls, { merge: true });
            batch.set(personStatus, ps, { merge: true });
         }
      });
      if (increaseStatus !== 0) {
         const increment = firebase.firestore.FieldValue.increment(increaseStatus);
         batch.set(statusLevel, { ito: increment }, { merge: true });
      }
      if (decreaseStatus !== 0) {
         const decrement = firebase.firestore.FieldValue.increment(decreaseStatus);
         batch.set(statusLevel, { '-': decrement }, { merge: true });
      }
      batch.commit();
   };

   offSiteLocation = async (data, status, form, routine, dayTime) => {
      const user = data.user;
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const batch = this.db.batch();
      if (form.lid) {
         let oldStatus = status.set;
         if (status.originalStatus) {
            oldStatus = status.originalStatus;
         }
         const oldLid = status.lid;
         const oldLoc = this.db.doc(`location/${oldLid}/schedule/${data.date.ref}`);
         let lsOld = { [user.uid]: deleteField };
         batch.set(oldLoc, lsOld, { merge: true });
         const oldStatusLevel = this.db
            .collection(`location/${oldLid}/statusLevels/`)
            .doc(`${data.date.ref}`);
         let slOld = { [oldStatus]: decrement };
         batch.set(oldStatusLevel, slOld, { merge: true });
         const newStatusLevel = this.db
            .collection(`location/${form.lid}/statusLevels/`)
            .doc(`${data.date.ref}`);
         let slNew = { offsite: increment };
         batch.set(newStatusLevel, slNew, { merge: true });
      }
      if (status.offsiteLocation?.locationId) {
         const offLocStatusLevel = this.db
            .collection(`location/${status.offsiteLocation.locationId}/statusLevels/`)
            .doc(`${data.date.ref}`);
         let offLocSL = { offsiteIto: decrement };
         batch.set(offLocStatusLevel, offLocSL, { merge: true });
      }
      if (
         dayTime &&
         status.offsiteLocation &&
         status.offsiteLocation[dayTime]?.locationId
      ) {
         const offLocStatusLevel = this.db
            .collection(
               `location/${status.offsiteLocation[dayTime].locationId}/statusLevels/`,
            )
            .doc(`${data.date.ref}`);
         let offLocSL = { [dayTime]: { offsiteIto: decrement } };
         batch.set(offLocStatusLevel, offLocSL, { merge: true });
      }
      const loc = this.db.doc(`location/${user.lid}/schedule/${data.date.ref}`);
      let offLoc = form.input;
      if (form.locationId) {
         offLoc = {
            locationId: form.locationId,
            locationName: form.locationName,
         };
         const offLocStatusLevel = this.db
            .collection(`location/${form.locationId}/statusLevels/`)
            .doc(`${data.date.ref}`);
         let offLocSL = {};
         if (!dayTime) {
            offLocSL = { offsiteIto: increment };
         } else {
            offLocSL = { [dayTime]: { offsiteIto: increment } };
         }
         batch.set(offLocStatusLevel, offLocSL, { merge: true });
      }
      if (dayTime) {
         offLoc = {
            [dayTime]: form.input,
         };
         if (form.locationId) {
            offLoc = {
               [dayTime]: {
                  locationId: form.locationId,
                  locationName: form.locationName,
               },
            };
         }
         if (!form.locationId) {
            let resetOffsiteLocation = false;
            if (status.offsiteLocation) {
               if (
                  dayTime === 'AM' &&
                  (!status.offsiteLocation.PM || status.offsiteLocation.PM === '')
               ) {
                  resetOffsiteLocation = true;
               }
               if (
                  dayTime === 'PM' &&
                  (!status.offsiteLocation.AM || status.offsiteLocation.AM === '')
               ) {
                  resetOffsiteLocation = true;
               }
               if (resetOffsiteLocation) offLoc = '';
            }
         }
      }
      let locUpdate = {
         [user.uid]: {
            offsiteLocation: offLoc,
         },
      };
      let ps = { lid: user.lid, offsiteLocation: offLoc };
      if (!dayTime) {
         locUpdate[user.uid].status = 'offsite';
         ps.status = 'offsite';
      }
      if (routine) {
         locUpdate[user.uid] = {
            ...locUpdate[user.uid],
            routine: 'offsite',
            overriden: false,
         };
         ps = { ...ps, lid: user.lid, routine: 'offsite', overridden: false };
      }
      batch.set(loc, locUpdate, { merge: true });
      const person = this.db.doc(
         `company/${user.cid}/people/${user.uid}/schedule/${data.date.ref}`,
      );
      batch.set(person, ps, { merge: true });
      batch.commit();
   };
   removeOffSiteLocation = async (data) => {
      const user = data.user;
      const date = data.date.ref;
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      const batch = this.db.batch();
      if (data.status.originalStatus) {
         const oldLid = data.status.lid;
         const oldLoc = this.db.doc(`location/${oldLid}/schedule/${data.date.ref}`);
         let lsOld = { [user.uid]: deleteField };
         batch.set(oldLoc, lsOld, { merge: true });
         const oldStatusLevel = this.db
            .collection(`location/${oldLid}/statusLevels/`)
            .doc(`${data.date.ref}`);
         let slOld = { [data.status.originalStatus]: decrement };
         batch.set(oldStatusLevel, slOld, { merge: true });
         const newStatusLevel = this.db
            .collection(`location/${user.lid}/statusLevels/`)
            .doc(`${data.date.ref}`);
         let slNew = { offsite: increment };
         batch.set(newStatusLevel, slNew, { merge: true });
      }
      if (data.offsiteLocation?.locationId) {
         const offLocStatusLevel = this.db
            .collection(`location/${data.offsiteLocation.locationId}/statusLevels/`)
            .doc(`${data.date.ref}`);
         let offLocSL = { offsiteIto: decrement };
         batch.set(offLocStatusLevel, offLocSL, { merge: true });
      }
      if (data.dayTime && data.offsiteLocation[data.dayTime]?.locationId) {
         const offLocStatusLevel = this.db
            .collection(
               `location/${data.offsiteLocation[data.dayTime].locationId}/statusLevels/`,
            )
            .doc(`${data.date.ref}`);
         let offLocSL = { [data.dayTime]: { offsiteIto: decrement } };
         batch.set(offLocStatusLevel, offLocSL, { merge: true });
      }
      const loc = this.db.doc(`location/${user.lid}/schedule/${date}`);
      let locUpdate = {
         [user.uid]: {
            lid: user.lid,
            status: 'offsite',
            offsiteLocation: {
               [data.dayTime]: deleteField,
            },
         },
      };
      let personUpdate = {
         lid: user.lid,
         status: 'offsite',
         offsiteLocation: {
            [data.dayTime]: deleteField,
         },
      };
      if (data.dayTime) {
         locUpdate[user.uid].status = 'split';
         personUpdate.status = 'split';
         if (
            data.dayTime === 'AM' &&
            (data.offsiteLocation.PM === '' || !data.offsiteLocation.PM)
         ) {
            locUpdate[user.uid].offsiteLocation = '';
            personUpdate.offsiteLocation = '';
         }
         if (
            data.dayTime === 'PM' &&
            (data.offsiteLocation.AM === '' || !data.offsiteLocation.AM)
         ) {
            locUpdate[user.uid].offsiteLocation = '';
            personUpdate.offsiteLocation = '';
         }
      } else {
         locUpdate[user.uid].offsiteLocation = '';
         personUpdate.offsiteLocation = '';
      }
      batch.set(loc, locUpdate, { merge: true });
      const person = this.db.doc(
         `company/${user.cid}/people/${user.uid}/schedule/${date}`,
      );

      batch.set(person, personUpdate, { merge: true });
      batch.commit();
   };

   resetDayForUser = (data) => {
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const deleteField = firebase.firestore.FieldValue.delete();
      var schedDocRef = this.db.doc(
         `company/${data.user.cid}/people/${data.user.uid}/schedule/${data.date.ref}`,
      );

      return this.db
         .runTransaction((transaction) => {
            return transaction.get(schedDocRef).then((sfDoc) => {
               let result = sfDoc.data();
               if (result.bookingNo && result.bookingNo > 0) {
                  Object.entries(result.bookings).map(([bid, booking]) => {
                     let spaceBookings = this.db.doc(
                        `location/${booking.lid}/spaceBookings/${data.date.ref}`,
                     );
                     let spaceBookingUpdate = {
                        [booking.sid]: {
                           bookingNo: decrement,
                           bookings: {
                              [bid]: deleteField,
                           },
                        },
                     };
                     transaction.set(spaceBookings, spaceBookingUpdate, { merge: true });
                     let spaces = this.db.doc(
                        `location/${booking.lid}/spaces/${booking.sid}/schedule/${data.date.ref}`,
                     );
                     let spacesUpdate = {
                        bookingNo: decrement,
                        bookings: {
                           [bid]: deleteField,
                        },
                     };
                     transaction.set(spaces, spacesUpdate, { merge: true });
                  });
               }
               let locSched = this.db.doc(
                  `location/${result.lid}/schedule/${data.date.ref}`,
               );
               let locSchedUpdate = { [data.user.uid]: deleteField };
               transaction.set(locSched, locSchedUpdate, { merge: true });
               let locSL = this.db.doc(
                  `location/${result.lid}/statusLevels/${data.date.ref}`,
               );
               let locSLUpdate = {};
               if (result.status === 'split') {
                  locSLUpdate = {
                     AM: {
                        [result.splitDay.AM]: decrement,
                        // "-" : increment,
                     },
                     PM: {
                        [result.splitDay.PM]: decrement,
                        // "-" : increment,
                     },
                  };
               } else {
                  locSLUpdate = {
                     [result.status]: decrement,
                     // "-" : increment,
                  };
               }
               transaction.set(locSL, locSLUpdate, { merge: true });
               let coUser = this.db.doc(
                  `company/${data.user.cid}/people/${data.user.uid}/schedule/${data.date.ref}`,
               );
               transaction.delete(coUser);
            });
         })
         .then(() => {
            console.log('Transaction successfully committed!');
         })
         .catch((error) => {
            console.log('Transaction failed: ', error);
         });
   };

   getStatusLevelsDay = (lid, day) =>
      this.db.collection(`location/${lid}/statusLevels/`).doc(`${day}`);
   getStatusLevelsStreaks = (lid, start, end) =>
      this.db
         .collection(`location/${lid}/statusLevels/`)
         .where(firebase.firestore.FieldPath.documentId(), '>=', start)
         .where(firebase.firestore.FieldPath.documentId(), '<=', end);
   getStatusLevelsStreak = (lid, dates) =>
      this.db
         .collection(`location/${lid}/statusLevels/`)
         .where(firebase.firestore.FieldPath.documentId(), 'in', dates);
   getRoutineStreak = (lid) => this.db.doc(`location/${lid}/statusLevels/routine`);
   //** check user in **/
   checkInUser = (data) => {
      const batch = this.db.batch();
      const time = firebase.firestore.FieldValue.serverTimestamp();
      const deleteField = firebase.firestore.FieldValue.delete();
      const locationschedule = this.db
         .collection(`location/${data.lid}/schedule`)
         .doc(`${data.date.ref}`);
      const userUpdate = this.db.doc(
         `company/${data.cid}/people/${data.uid}/schedule/${data.date.ref}`,
      );
      if (data.details.checkedOut) {
         batch.set(
            locationschedule,
            {
               [data.uid]: {
                  checkedInTime: time,
                  checkedOut: deleteField,
                  checkedOutTime: deleteField,
               },
            },
            { merge: true },
         );
         batch.set(
            userUpdate,
            {
               checkedInTime: time,
               checkedInTo: data.lid,
               checkedOut: deleteField,
               checkedOutTime: deleteField,
               checkedOutTo: deleteField,
            },
            { merge: true },
         );
      } else if (data.details.checkedIn) {
         batch.set(
            locationschedule,
            { [data.uid]: { checkedOut: true, checkedOutTime: time } },
            { merge: true },
         );
         batch.set(
            userUpdate,
            { checkedOut: true, checkedOutTime: time, checkedOutTo: data.lid },
            { merge: true },
         );
      } else {
         batch.set(
            locationschedule,
            { [data.uid]: { checkedIn: true, checkedInTime: time } },
            { merge: true },
         );
         batch.set(
            userUpdate,
            { checkedIn: true, checkedInTime: time, checkedInTo: data.lid },
            { merge: true },
         );
      }
      batch.commit();
   };

   wholeCollection = (path) => this.db.collection(`${path}`);
   doc = (path) => this.db.doc(`${path}`);

   //*** attempted cloud function ****/
   addUser = (data) => this.functions.httpsCallable('addMessage');
   inviteUser = (data) => this.functions.httpsCallable('UserInvitation');

   //**** attempted cloud storage ****/

   showImage = (url) => this.storage.ref().child(`${url}`);
   defaultOfficeImage = () =>
      this.storage.ref().child('imagesdefaultOfficeImage/office.jpeg');
   uploadImage = (file, location) =>
      this.storage
         .ref()
         .child(location + '/' + file.name)
         .put(file);
   storedImageRef = (path) => this.storage.ref().child(path);
   listFiles = (path) => this.storage.ref().child(`${path}`);
}

export default Firebase;
